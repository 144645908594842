<script setup lang="ts">
import CallToAction from "@/components/marketing/CallToAction.vue";
import Header from "@/components/marketing/home/Header.vue";
import UserStats from "@/components/marketing/home/UserStats.vue";
import HowItWorks from "@/components/marketing/home/HowItWorks.vue";

const data = await useSeoAndGetContent(ROUTES.home);
const { howItWorks, header } = data;

definePageMeta({
  layout: "marketing",
});
</script>

<template>
  <div class="flex flex-col gap-44">
    <Header :header="header" />
    <HowItWorks :how-it-works="howItWorks" />
    <CallToAction />
  </div>
</template>
