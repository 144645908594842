<script setup lang="ts">
import type { HomePageHeader } from "@/types";
import UserActionModal from "@/components/marketing/UserActionModal.vue";
import TypeText from "@/components/marketing/TypeText.vue";

interface Props {
  header: HomePageHeader;
}

defineProps<Props>();

const userActionModalActive = ref(false);

const toggleUserActionModal = (active: boolean) => {
  userActionModalActive.value = active;
};
</script>

<template>
  <section class="relative isolate mx-auto max-w-7xl">
    <svg
      class="absolute inset-x-0 top-0 -z-10 h-[32rem] lg:h-[54rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
      aria-hidden="true"
    >
      <defs>
        <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
          <path d="M.5 200V.5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
      </svg>
      <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
    </svg>
    <div class="overflow-hidden xl:overflow-visible">
      <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
        <div class="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl p-1">
          <h1 class="text-dark-gray">
            <TypeText :text="header.title" :key="header.title" size="large" />
          </h1>
          <p class="mt-6 text-lg leading-8 text-medium-gray sm:max-w-md lg:max-w-none">
            {{ header.description }}
          </p>
          <div class="mt-10">
            <PrimaryButton @click="toggleUserActionModal(true)" large>{{ header.cta.text }}</PrimaryButton>
          </div>
        </div>
        <div class="mt-14 hidden lg:flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
          <div class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
            <div class="relative">
              <BaseImage
                src="/home/barista-laughing.png"
                alt="Barista"
                sizes="300px"
                class="aspect-[2/3] w-full rounded-xl bg-dark-gray/5 object-cover shadow-lg"
              />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-dark-gray/10" />
            </div>
          </div>
          <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
            <div class="relative">
              <BaseImage
                src="/home/chef.png"
                alt="Chef"
                sizes="300px"
                class="aspect-[2/3] w-full rounded-xl bg-dark-gray/5 object-cover shadow-lg"
              />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-dark-gray/10" />
            </div>
            <div class="relative">
              <BaseImage
                src="/home/store-clerk.jpg"
                alt="Store Clerk"
                sizes="300px"
                class="aspect-[2/3] w-full rounded-xl bg-dark-gray/5 object-cover shadow-lg"
              />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-dark-gray/10" />
            </div>
          </div>
          <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
            <div class="relative">
              <BaseImage
                src="/home/retail-clerk.jpg"
                alt="Retail Clerk"
                sizes="300px"
                class="aspect-[2/3] w-full rounded-xl bg-dark-gray/5 object-cover shadow-lg"
              />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-dark-gray/10" />
            </div>
            <div class="relative">
              <BaseImage
                src="/home/camera-operator.jpg"
                alt="Camera Operator"
                sizes="300px"
                class="aspect-[2/3] w-full rounded-xl bg-dark-gray/5 object-cover shadow-lg"
              />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-dark-gray/10" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <UserActionModal :show="userActionModalActive" @close="toggleUserActionModal(false)" />
  </section>
</template>
